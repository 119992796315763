<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Search task"
              @input="updateRouteQuery"
            />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <!-- <div class="dropdown">
          <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="resetSortAndNavigate">
              Reset Sort
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: $route.name,
                query: { ...$route.query, sort: 'asc' },
              }"
            >
              Sort A-Z
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: $route.name,
                query: { ...$route.query, sort: 'desc' },
              }"
            >
              Sort Z-A
            </b-dropdown-item>

         
          </b-dropdown>
        </div> -->
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-model="tasks"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <li
            v-for="task in tasks"
            :key="task.id"
            class="todo-item"
            :class="{ completed: task.isCompleted }"
            @click="handleTaskClick(task)"
          >
            <feather-icon
              icon="MoreVerticalIcon"
              class="draggable-task-handle d-inline"
            />
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox
                    :checked="task.isCompleted"
                    @click.native.stop
                    @change="updateTaskIsCompleted(task)"
                  />
                  <span class="todo-title">{{ task.title }}</span>
                </div>
              </div>
              <div class="todo-item-action">
                <div class="badge-wrapper mr-1">
                  <b-badge
                    v-if="task.isImportant"
                    pill
                    :variant="`light-${resolveTagVariant('Important')}`"
                    class="text-capitalize"
                  >
                    {{ "Important" }}
                  </b-badge>
                  <b-badge
                    v-if="task.isCompleted"
                    pill
                    :variant="`light-${resolveTagVariant('isCompleted')}`"
                    class="text-capitalize"
                  >
                    {{ "Completed" }}
                  </b-badge>
                  <b-badge
                    v-if="task.isDeleted"
                    pill
                    :variant="`light-${resolveTagVariant('isDeleted')}`"
                    class="text-capitalize"
                  >
                    {{ "Deleted" }}
                  </b-badge>
                </div>
                <small class="text-nowrap text-muted mr-1">{{
                  formatDate(task.dueDate, { month: "short", day: "numeric" })
                }}</small>
                <b-avatar
                  v-if="task.assignee"
                  size="32"
                  :src="getAvatarUrl(task.assigneeAvatarUrl)"
                  :text="avatarText(task.assignee.userFullName)"
                />
                <b-avatar v-else size="32" variant="light-secondary">
                  <feather-icon icon="UserIcon" size="16" />
                </b-avatar>
              </div>
            </div>
          </li>
        </draggable>
        <div class="no-results" :class="{ show: !tasks.length }">
          <h5>No Items Found</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Task Handler -->
    <todo-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :assigneeOptions="assigneeOptions"
      :clear-task-data="clearTaskData"
      @remove-task="removeTask"
      @add-task="addTask"
      @update-task="updateTask"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar
        :task-tags="taskTags"
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{ show: mqShallShowLeftSidebar }"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import store from "@/store";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
  BAvatar,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import { formatDate, avatarText } from "@core/utils/filter";
import { useRouter } from "@core/utils/utils";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import TodoLeftSidebar from "./TodoLeftSidebar.vue";
import todoStoreModule from "./todoStoreModule";
import TodoTaskHandlerSidebar from "./TodoTaskHandlerSidebar.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,

    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = "app-todo";

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME))
      store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME))
        store.unregisterModule(TODO_APP_STORE_MODULE_NAME);
    });

    const { route, router } = useRouter();
    const routeSortBy = computed(() => route.value.query.sort);
    const routeQuery = computed(() => route.value.query.q);
    const routeParams = computed(() => route.value.params);
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTasks();
      fetchUsers();
    });

    const tasks = ref([]);
    const assigneeOptions = ref([]);

    const sortOptions = [
      "latest",
      "title-asc",
      "title-desc",
      "assignee",
      "due-date",
    ];
    const sortBy = ref(routeSortBy.value);
    watch(routeSortBy, (val) => {
      if (sortOptions.includes(val)) sortBy.value = val;
      else sortBy.value = val;
    });
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

      delete currentRouteQuery.sort;

      router
        .replace({ name: route.name, query: currentRouteQuery })
        .catch(() => {});
    };

    const blankTask = {
      id: null,
      title: "",
      dueDate: new Date(),
      description: "",
      assignee: null,
      isImportant: false,
    };
    const task = ref(JSON.parse(JSON.stringify(blankTask)));
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask));
    };

    const addTask = (val) => {
      var data = {
        ...val,
        calendar: "Todos"
      };
     if(val.assignee)
     {
        data = {
        ...data,
        assigneeId: val.assignee.userId,
      };
     }
      store
        .dispatch("app-todo/addTask", data)
        .then(() => {
          fetchTasks();
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:value,
            },
          });
          }
        });
    };
    const removeTask = () => {
      store
        .dispatch("app-todo/removeTask", { id: task.value.id })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks();
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:value
            },
          });
          }
        });
    };
    const updateTask = (taskData) => {
      
      var data = {
        ...taskData,
        calendar: "Todos",
      };
 if(taskData.assignee )
     {
        data = {
        ...data,
         assigneeId: taskData.assignee.userId ,
      };
     }
      store
        .dispatch("app-todo/updateTask", { task: data })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks();
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:value,
            },
          });
          }
        });
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const isTaskHandlerSidebarActive = ref(false);

    const taskTags = [];

    const resolveTagVariant = (tag) => {
      if (tag === "isDeleted") return "primary";
      if (tag === "isCompleted") return "success";
      if (tag === "Important") return "danger";
      return "primary";
    };

    const resolveAvatarVariant = (tags) => {
      if (tags.includes("high")) return "primary";
      if (tags.includes("medium")) return "warning";
      if (tags.includes("low")) return "success";
      if (tags.includes("update")) return "danger";
      if (tags.includes("team")) return "info";
      return "primary";
    };
   const getAvatarUrl=(Url)=>{
    return  process.env.VUE_APP_BASE_URL + Url;
   }


    // Search Query
    const searchQuery = ref(routeQuery.value);
    watch(routeQuery, (val) => {
      searchQuery.value = val;
    });
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, sortBy], () => fetchTasks());
    const updateRouteQuery = (val) => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

      if (val) currentRouteQuery.q = val;
      else delete currentRouteQuery.q;

      router.replace({ name: route.name, query: currentRouteQuery });
    };

    const fetchTasks = () => {
      store
        .dispatch("app-todo/fetchTasks", {
          q: searchQuery.value,
          filter: router.currentRoute.params.filter,
          tag: router.currentRoute.params.tag,
          sortBy: sortBy.value,
        })
        .then((response) => {
          const data = response.data.items;
          let item = [];
          data.map((x) => {
            item.push({
              ...x,
              assignee: {
                userId: x.assigneeId,
                userFullName: x.assigneeFullName,
              },
            });
          });

          tasks.value = item;
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:value,
            },
          });
          }
        });
    };

    fetchTasks();

    const fetchUsers = () => {
      store
        .dispatch("app-todo/fetchUsers", {
          pageSize: 2000,
          currentPage: 1,
          sortExpression: null,
        })
        .then((response) => {
          const { data } = response.data;
          assigneeOptions.value = data;
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:value,
            },
          });
          }
        });
    };
    fetchUsers();

    const handleTaskClick = (taskData) => {
      task.value = taskData;
      isTaskHandlerSidebarActive.value = true;
    };

    // Single Task isCompleted update
    const updateTaskIsCompleted = (taskData) => {
      // eslint-disable-next-line no-param-reassign
      taskData.isCompleted = !taskData.isCompleted;
      updateTask(taskData);
    };

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    return {
      task,
      tasks,
      assigneeOptions,
      removeTask,
      addTask,
      updateTask,
      clearTaskData,
      taskTags,
      searchQuery,
      fetchTasks,
      fetchUsers,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,
      getAvatarUrl,
      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
